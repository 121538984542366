<style lang="less" scoped>
#orderlist {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-status {
    width: 150px;
    margin-right: 14px;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="orderlist" id="orderlist">
    <div class="tab-top">
      <table-title />
      <table-action alias="order" :hasSearch="false" :hasAdd="false">
        <div slot="custom">
          <a-select placeholder="请选择状态" class="select-status" @change="selectOrderStatus">
            <a-select-option :key="0">全部</a-select-option>
            <a-select-option :key="1">待完结</a-select-option>
            <a-select-option :key="2">已完结</a-select-option>
            <a-select-option :key="3">已撤销</a-select-option>
          </a-select>
          <!-- <a-button icon="export" type="primary" @click="exportClick">导出</a-button> -->
          <order-export @filterHandler="exportClick"></order-export>
        </div>
      </table-action>
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" v-show="record.order_status==1" @click="onEdit(record.order_id)">完结</span>
        <a-divider type="vertical" v-show="record.order_status==1" />
        <span class="model-action" v-show="record.order_status==1" @click="revoke(record.id)">撤销</span>
        <!-- <a-divider type="vertical" />
        <table-delete @comfirm="comfirmDelete(order_id)"></table-delete> -->
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchOrderlist, finishOrder, revokeorder } from "@/api/order.js";
const columns = [
  {
    title: "订单ID",
    dataIndex: "order_id",
  },
  {
    title: "商品名称",
    dataIndex: "commodity_name",
  },
  {
    title: "订单价格",
    dataIndex: "commodity_price",
  },
  {
    title: "订单状态",
    dataIndex: "order_status_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    // dataIndex: "order_id",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "Order",
  components: {
    "order-export": resolve =>
      require(["@/components/model/OrderExprot.vue"], resolve),
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve)
  },
  data() {
    return {
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      selectStatus: undefined,
      loading: true
    };
  },
  created() {
    this.getOrderlist();
  },
  methods: {
    async getOrderlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchOrderlist({
          page_size: this.size,
          page: this.current,
          order_status: this.selectStatus,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.exchange_order_list.map((item, index) => {
          var orderStatus = "待完结";
          switch (item.order_status) {
            case 1:
              orderStatus = "待完结";
              break;
            case 2: 
              orderStatus = "已完结";
              break;
            case 3:
              orderStatus = "已撤销";
              break;
            default:
              break;
          }
          item.order_status_text = orderStatus; 
          item["key"] = item.order_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async comfirmDelete(memberId) {
      this.$message.success("删除成功");
      this.getOrderlist();
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getOrderlist();
    },
    selectOrderStatus(e) {
      this.selectStatus = e;
      if (e == 0) {
        this.selectStatus = undefined;  
      }
      this.current = 1;
      this.getOrderlist();
    },
    onEdit(orderId) {
      this.updateOrderStatus(orderId);
    },
    async updateOrderStatus(orderId) {
      try {
        await finishOrder({
          order_id: orderId
        })
        this.$message.success("已完结");
        this.getOrderlist();
      } catch (error) {
        console.log(error)
      }
    },
    /// 撤销
    revoke(orderId) {
      this.revokeOrderStatus(orderId);
    },
    async revokeOrderStatus(orderId) {
      try {
        await revokeorder({
          order_id: orderId
        })
        this.$message.success("已撤销");
        this.getOrderlist();
      } catch (error) {
        console.log(error)
      }
    },
    /// 导出数据
    exportClick(e) {
      window.location.href = '/api/backstage/order/export?admin_id='+ this.adminId +'&password='+ this.pwd +'&order_status='+ this.selectStatus +'&begin_time='+ e.begin +'&end_time='+ e.end;
    },
  }
};
</script>

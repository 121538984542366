import https from '../utils/https'

// 订单列表接口
export function fetchOrderlist(params) {
  return https.fetchGet('/api/backstage/order/list', params)
}

// 修改订单状态
export function finishOrder(params) {
  return https.fetchGet('/api/backstage/order/finishorder', params)
}

// 撤销
export function revokeorder(params) {
  return https.fetchGet('/api/backstage/order/revokeorder', params)
}
